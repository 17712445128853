import { FC, useCallback, useEffect } from 'react';
import { QuizConfig } from '../../models/Form';
import Checkbox, { SliderSize } from '../shared/form-control/Checkbox';
import { useTranslation } from 'react-i18next';
import { Input } from '../shared/form-control/Input';
import PropertyLayout from './PropertyLayout';

type Props = {
  preferences?: QuizConfig | null;
  onChange: (value: QuizConfig) => void;
};

// Default initial QuizConfig structure
const defaultPreferences: QuizConfig = {
  allowedAttempts: 1,
  passRatePercentage: 50,
  showAnswersOnComplete: false,
};

const QuizConfiguration: FC<Props> = ({ onChange, preferences }) => {
  const { t } = useTranslation(['form-builder']);

  const isUnlimited = preferences?.allowedAttempts === -1;

  // Dedicated function to handle updates to quiz configuration
  const handleChange = useCallback(
    (key: keyof QuizConfig, value: QuizConfig[keyof QuizConfig]) => {
      onChange({
        ...(preferences ?? defaultPreferences), // Create a new object if preferences is null
        [key]: value,
      });
    },
    [onChange, preferences],
  );

  useEffect(() => {
    if (!preferences) {
      onChange(defaultPreferences);
    }
  }, [preferences, onChange]);

  return (
    <div className="mt-4">
      <PropertyLayout
        label={t('form-builder:form-properties.quiz.pass-rate.label')}
        description={t('form-builder:form-properties.quiz.pass-rate.description')}
      >
        <div className="flex w-24 items-center gap-2">
          <Input
            type="number"
            value={`${preferences?.passRatePercentage ?? defaultPreferences.passRatePercentage}`}
            min={1}
            max={100}
            onChange={(e) => handleChange('passRatePercentage', parseFloat(e.target.value) || 0)}
          />
          <span className="text-gray-3">%</span>
        </div>
      </PropertyLayout>
      <PropertyLayout
        label={t('form-builder:form-properties.quiz.attempts.label')}
        description={t('form-builder:form-properties.quiz.attempts.description')}
      >
        <div className="flex items-center gap-4">
          <div className="w-20">
            <Input
              type="number"
              value={isUnlimited ? '' : `${preferences?.allowedAttempts ?? defaultPreferences.allowedAttempts}`}
              disabled={isUnlimited}
              onChange={(e) => handleChange('allowedAttempts', parseInt(e.target.value) || 0)}
            />
          </div>
          <span>or</span>
          <Checkbox
            value={isUnlimited}
            onChange={(value) => handleChange('allowedAttempts', value ? -1 : 1)}
            label={t('form-builder:form-properties.quiz.attempts.unlimited')}
          />
        </div>
      </PropertyLayout>
      <div className="mt-4">
        <Checkbox
          labelBeforeCheckbox
          slider
          sliderSize={SliderSize.S}
          value={preferences?.showAnswersOnComplete ?? false}
          onChange={(value) => handleChange('showAnswersOnComplete', value)}
          label={t('form-builder:form-properties.quiz.showAnswerOnCompletion.label')}
          description={t('form-builder:form-properties.quiz.showAnswerOnCompletion.description')}
        />
      </div>
    </div>
  );
};

export default QuizConfiguration;
