/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentProps, ElementRef, FC, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ActionTypesList from '../../components/form-builder/ActivityTypesList';
import FormBuilderEditor, { FormBuilderDragOverlay } from './FormBuilderEditor';
import { v4 as uuid } from 'uuid';
import {
  Action,
  ActionPropertiesProps,
  BuilderDroppableTypes,
  EditorType,
  FormBuilderForm,
  FormBuilderPlaceholder,
  FormPropertiesProps,
  Section,
  SectionPropertiesProps,
} from '../../components/form-builder/FormBuilderTypes';
import ActionTypes, { ActionTypesInfo, useActionTypeBuilderIdGenerator } from '../../components/form/ActionTypes';
import FormActionProperties from './FormActionProperties';
import FormProperties from '../../components/form-builder/FormProperties';
import { TabStrip, useTabHeader } from '../../components/shared/tab-strip/TabStrip';
import tailwindConfig from 'tailwind.config.js';
import tailwindResolve from 'tailwindcss/resolveConfig';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TemplateFormService from '../../services/TemplateFormService';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import PageLoader from '../../components/shared/page-loader/PageLoader';
import { FormConfig } from '../../models/Form';
import StaticBreadCrumb, { BreadCrumbItem } from '../../components/shared/breadcumb/StaticBreadCrumb';
import Button, { ButtonType } from '../../components/shared/form-control/Button';
import FormSectionProperties from './FormSectionProperties';
import ObjectUtils from '../../utils/ObjectUtils';
import { FormType, FormTypeValues } from '../../models/FormTypes';
import DateUtils from '../../utils/DateUtils';
import { SHA256, AES, enc } from 'crypto-js';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import ClientTemplateFormService from '../../services/ClientTemplateFormService';
import RouteGuard from '../../components/shared/RouteGuard';
import FormRendererV2 from '../../components/form/renderer/FormRendererV2';
import { nextTick } from '../../utils/ReactUtils';
import { FormRendererMode } from '../../contexts/FormRendererDesignContextTypes';
import { useTranslation } from 'react-i18next';
import { DndContext, DragEndEvent, DragOverEvent, DragOverlay, MeasuringStrategy, UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import ContextMenu from '../shared/ContextMenu';
import CopyIcon from '../shared/icon/CopyIcon';
import EditIcon from '../shared/icon/EditIcon';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import { PeriodicReviewUtils } from '../../utils/PeriodicReviewUtils';
import { ParseKeys } from 'i18next';
import TranslatableInputButtons from '../shared/TranslatableInputButtons';
import LanguageUtils from '../../utils/LanguageUtils';
import { useCurrentClient, useCurrentTenantId } from '../../global-state/Clients';
import { useTopNavHeading } from '../../global-state/Workspace';

const tailwind = tailwindResolve(tailwindConfig);

const INVALID_STATE_FORM_TYPE_MISSING = 'form-builder:error-states.form-type-missing';
const INVALID_STATE_FORM_TITLE_MISSING = 'form-builder:error-states.form-title-missing';
const INVALID_STATE_SECTION_NAME_MISSING = 'form-builder:error-states.section-name-missing';
const INVALID_STATE_SECTION_VISBLE_CONDITION_NOT_COMPLETE = 'form-builder:error-states.section-visible-condition-incomplete';
const INVALID_STATE_ACTION_NOT_USABLE_IN_PUBLIC_SECTION = 'form-builder:error-states.action-not-usable-in-public-section';
const INVALID_STATE_STEP_TRIGGER_STEP_DELETED = 'form-builder:error-states.step-trigger-deleted-step';
const INVALID_STATE_STEP_TRIGGER_STEP_MOVED = 'form-builder:error-states.step-trigger-step-moved';
const INVALID_TRIGGER_STEP_IS_DRAFT = 'form-builder:error-states.step-trigger-in-draft';
const INVALID_STATE_STEP_TRIGGER_ACTION_FROM_DIFFERENT_STEP = 'form-builder:error-states.step-trigger-action-from-different-step';
const INVALID_STATE_SIMILAR_FIELD_LABELS = 'form-builder:error-states.similar-field-labels';

type FormBuilderBaseProps = {
  initialForm: FormBuilderForm;
  topNavHeadingKey: ParseKeys<['form-builder']>;
  failsafeKey: string;
  breadCrumbs: BreadCrumbItem[];
  hidePreview?: boolean;
  actionTypes?: Record<keyof typeof ActionTypes, ActionTypesInfo>;
  PropertiesEditors?: {
    ActionPropertiesEditor: FC<ActionPropertiesProps>;
    SectionPropertiesEditor: FC<SectionPropertiesProps>;
    FormPropertiesEditor: FC<FormPropertiesProps>;
  };
};

const DefaultPropertiesEditors: FormBuilderBaseProps['PropertiesEditors'] = {
  ActionPropertiesEditor: FormActionProperties,
  SectionPropertiesEditor: FormSectionProperties,
  FormPropertiesEditor: FormProperties,
};

const FormBuilderBase: FC<FormBuilderBaseProps> = (props) => {
  const {
    initialForm,
    hidePreview,
    topNavHeadingKey,
    actionTypes: actionTypesInfo = ActionTypes,
    failsafeKey,
    PropertiesEditors = DefaultPropertiesEditors,
    breadCrumbs,
  } = props;
  const currentClient = useCurrentClient((x) => x.value);
  const currentTenantId = useCurrentTenantId((x) => x.value);
  const [form, setForm] = useState<FormBuilderForm>(() => initialForm);
  const [originalForm, setOriginalForm] = useState<FormBuilderForm>(); // used to compare the changed form values against the original loaded one
  const [selectedSectionId, setSelectedSectionId] = useState('');
  const [selectedEditorType, setSelectedEditorType] = useState<string | null>(EditorType.FORM);
  const [selectedEditorId, setSelectedEditorId] = useState('');
  const [loading, setLoading] = useState(false);
  const { templateFormId, templateFormVersion } = useParams<{
    templateFormId: string | undefined;
    templateFormVersion: string | undefined;
  }>();
  const [searchParams] = useSearchParams();
  const [showJson, setShowJson] = useState(false);
  const { t, i18n } = useTranslation(['form-builder', 'periodic-review']);
  const [previewLanguageCode, setPreviewLanguageCode] = useState(i18n.language);
  const [changesPending, setChangesPending] = useState(false);
  const [loadedType, setLoadedType] = useState(initialForm.type);
  const [invalidStates, setInvalidStates] = useState<Record<string, string[]>>({});
  const [showInvalidStateModal, setShowInvalidStateModal] = useState(false);
  const [allowSavingInInvalidState, setAllowSavingInInvalidState] = useState(true);
  const [referencedForms, setReferencedForms] = useState<Record<string, FormConfig>>({});
  const [creatingCopy, setCreatingCopy] = useState(false);

  const { getId: getActionTypeId, generateNewIds: newActionTypeIds } = useActionTypeBuilderIdGenerator();
  const location = useLocation();
  const navigate = useNavigate();
  const toastsRef = useRef(useToasts());
  const setTopNavheading = useTopNavHeading((x) => x.setValue);
  const formRendererRef = useRef<ElementRef<typeof FormRendererV2>>(null);

  const leadingUrl = useMemo(() => {
    const path = location.pathname
      .replace(new RegExp(`\\/?builder\\/${currentTenantId}\\/?`), '')
      .replace(new RegExp(`\\/?clients\\/${currentClient?.id}\\/?`), '');

    const builder = path.match(/^\/?(.+?)(\/.*)?$/);

    return (currentClient ? `/clients/${currentClient.id}/` : `/builder/${currentTenantId}/`) + builder?.at(1);
  }, [currentClient, currentTenantId, location.pathname]);

  const filteredActionTypes = useMemo(() => {
    return Object.entries(actionTypesInfo)
      .filter(([type, info]) => (info.allowedInFormTypes && form.type ? (info.allowedInFormTypes as FormTypeValues[]).includes(form.type) : true))
      .reduce(
        (acc, [type, info]) => {
          acc[type] = info;
          return acc;
        },
        {} as Record<keyof typeof ActionTypes, ActionTypesInfo>,
      );
  }, [actionTypesInfo, form.type]);

  const actionTypes: Action[] = Object.entries(filteredActionTypes)
    .map(([type, info]) => ({
      id: getActionTypeId(type) || '',
      type: type,
      name: t(`form-builder:action-types.${type}`),
      data: null,
      hidden: info.hidden,
    }))
    .filter((x) => !x.hidden)
    .sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    setTopNavheading(t(topNavHeadingKey) as string);
    return () => {
      setTopNavheading('');
    };
  }, [setTopNavheading, t, topNavHeadingKey]);

  const templateService = useMemo(() => {
    if (currentClient) {
      return new ClientTemplateFormService(currentClient.id);
    }
    return TemplateFormService;
  }, [currentClient]);

  const fetchFormReference = useCallback(
    (id: string) => {
      if (!id) {
        return;
      }

      const key = id;
      if (key in referencedForms) {
        return;
      }

      templateService.getFormTemplate(id).then((res) => {
        setReferencedForms((prev) => ({
          ...prev,
          [key]: res.data,
        }));
      });
    },
    [referencedForms, templateService],
  );

  useEffect(
    function loadReferencedForms() {
      for (const section of form.sections) {
        for (const action of section.actions) {
          if (action.type === 'ChildFormListAction') {
            fetchFormReference(action.data?.templateFormId);
          } else if (action.type === 'ResourcePicklistAction' && action.data?.lockedTemplateId) {
            fetchFormReference(action.data?.lockedTemplateId);
          } else if (action.type === 'PreSelectedForm') {
            fetchFormReference(action.data?.templateFormId);
          }
        }
      }
    },
    [fetchFormReference, form.sections],
  );

  const selectedSection = useMemo(() => form.sections.find((section) => section.id === selectedSectionId), [form.sections, selectedSectionId]);

  const addInvalidState = useCallback((actionId: string, reason: string) => {
    setInvalidStates((prev) => ({ ...prev, [actionId]: [...new Set([...(prev[actionId] || []), reason])] }));
  }, []);

  // Form State validators return `null` when valid, or an array, `[message, allowSaving]`, where `message` is the displayed message,
  // and `allowSaving` is whether or not this error still allows saving. If any returns `false` for this value, saving of the form
  // is disabled.

  const validateConditional = useCallback((conditionString: string | undefined, message: string, expectedParts = 3): null | [string, boolean] => {
    if (conditionString === 'true' || conditionString === 'false') {
      return null;
    }

    const conditions = conditionString
      ? conditionString.split(/\s(or|and)\s/im).filter((condition) => condition !== 'and' && condition !== 'or')
      : [];
    if (
      conditions?.length === 0 ||
      !conditions.every((condition) => (condition?.replaceAll("''", '').trim().split(' ')?.length ?? 0) === expectedParts)
    ) {
      return [message, true];
    }

    return null;
  }, []);

  const validateRisk = useCallback(
    (riskEnabled: boolean | undefined, conditions: string[] | undefined, message: string): (null | [string, boolean])[] => {
      if (!riskEnabled) {
        return [null];
      }

      return conditions ? conditions.map((condition) => validateConditional(condition, message, 5)) : [];
    },
    [validateConditional],
  );

  const validateRequired = useCallback((value: string | undefined, message: string): null | [string, boolean] => {
    return value?.trim() ? null : [message, true];
  }, []);

  const validateAssetId = useCallback(
    (assetId: string | undefined, message: string): null | [string, boolean] => {
      if (!assetId) {
        return null;
      }

      const form = referencedForms[assetId];
      if (!assetId || !form || form.type !== FormType.Asset) {
        return null;
      }

      return form.isAssociated ? null : [message, true];
    },
    [referencedForms],
  );

  const validateDynamicDataSource = useCallback(
    (actionId: string | undefined, lockedToTemplateId: string | undefined, message: string): null | [string, boolean] => {
      if (!actionId) {
        return null;
      }
      const placeholder = form.placeholders?.find((x) => x.actionIdContainingAssociation === actionId);

      return placeholder && !lockedToTemplateId ? [message, false] : null;
    },
    [form.placeholders],
  );

  const disallowInPublicSection = useCallback(
    (actionId: string | undefined): null | [string, boolean] => {
      const isPublicSection = form.sections.find((section) => section.actions.some((action) => action.id === actionId))?.isPublic;

      return isPublicSection ? [t(INVALID_STATE_ACTION_NOT_USABLE_IN_PUBLIC_SECTION), false] : null;
    },
    [form.sections, t],
  );

  const conditionallyApplyRule = useCallback(
    (condition: ({ form, section }: { form: FormBuilderForm; section: Section }) => boolean, rule: () => null | [string, boolean]) => {
      const conditionPass = condition({ form, section: selectedSection as Section });
      if (conditionPass) {
        return rule();
      }

      return null;
    },
    [form, selectedSection],
  );

  const validateForm = useCallback(() => {
    setInvalidStates({});

    let allowSaving = true;
    const periodicValidationErrors = PeriodicReviewUtils.validate(form.periodicReviewConfig, t);
    if (periodicValidationErrors.length > 0) {
      allowSaving = false;
      for (const err of periodicValidationErrors) {
        addInvalidState(form.id, err);
      }
    }

    // Form type check
    if ((loadedType === undefined || loadedType === null) && (form.type === undefined || form.type === null)) {
      addInvalidState(form.id, t(INVALID_STATE_FORM_TYPE_MISSING));
    }

    if (!LanguageUtils.getTranslation('title', form.translations)?.trim()) {
      addInvalidState(form.id, t(INVALID_STATE_FORM_TITLE_MISSING));
      allowSaving = false;
    }

    for (const section of form.sections) {
      // Section titles check
      if (!LanguageUtils.getTranslation('title', section.translations)) {
        addInvalidState(section.id, t(INVALID_STATE_SECTION_NAME_MISSING));
      }

      // Section visibility check
      const sectionError = validateConditional(section.visible || '', t(INVALID_STATE_SECTION_VISBLE_CONDITION_NOT_COMPLETE));
      if (typeof sectionError === 'string') {
        addInvalidState(section.id, sectionError);
      }

      for (const action of section.actions) {
        const duplicatedFieldLabel = section.actions.find(
          (a) => !!a.data?.fieldLabel && a.id !== action.id && a.data?.fieldLabel === action.data?.fieldLabel,
        );
        if (!action.noninteractive && !!action.data?.fieldLabel && !!duplicatedFieldLabel) {
          addInvalidState(action.id, t(INVALID_STATE_SIMILAR_FIELD_LABELS));
        }
        const validator = ActionTypes[action.type].validate;
        if (validator) {
          const errors = validator(action, t, {
            validateConditional,
            validateRisk,
            validateRequired,
            validateAsset: validateAssetId,
            disallowInPublicSection,
            conditionallyApplyRule,
            validateDynamicDataSource,
          }).map((x) => (x === null ? null : Array.isArray(x[0]) ? x?.flat(1) : x));

          const errorMessages = errors.map((x) => (x === null ? null : x[0])).filter((error) => typeof error === 'string') as string[];
          for (const error of errorMessages) {
            addInvalidState(action.id, error);
          }

          allowSaving = allowSaving && errors.filter((x) => x).every((x) => x && x[1]);
        }
      }

      if (section.triggerSteps) {
        // Validate step triggers
        for (const stepTrigger of section.triggerSteps) {
          // get the index of the trigger step and check if it's not moved back before this step or deleted
          const triggerStepIndex = form.sections.findIndex((x) => x.id === stepTrigger.stepId);
          if (triggerStepIndex < 0) {
            addInvalidState(section.id, t(INVALID_STATE_STEP_TRIGGER_STEP_DELETED));
            allowSaving = false;
          } else {
            const currentSectionIndex = form.sections.indexOf(section);
            if (triggerStepIndex < currentSectionIndex) {
              addInvalidState(section.id, t(INVALID_STATE_STEP_TRIGGER_STEP_MOVED));
              allowSaving = false;
            }
          }

          const actionId = stepTrigger.condition.split('$')[0];
          if (actionId && !section.actions.find((x) => x.id === actionId)) {
            addInvalidState(section.id, t(INVALID_STATE_STEP_TRIGGER_ACTION_FROM_DIFFERENT_STEP));
            allowSaving = false;
          }
        }
      }

      if (section.isDraft && form.sections.filter((x) => x.triggerSteps.findIndex((t) => t.stepId === section.id) > -1).length) {
        addInvalidState(section.id, t(INVALID_TRIGGER_STEP_IS_DRAFT));
        allowSaving = false;
      }
    }

    setAllowSavingInInvalidState(allowSaving);

    return () => {
      setInvalidStates({});
    };
  }, [
    addInvalidState,
    conditionallyApplyRule,
    disallowInPublicSection,
    form.id,
    form.periodicReviewConfig,
    form.sections,
    form.translations,
    form.type,
    loadedType,
    t,
    validateAssetId,
    validateConditional,
    validateDynamicDataSource,
    validateRequired,
    validateRisk,
  ]);

  const invalidStateMessages = useMemo(() => {
    return Object.entries(invalidStates)
      .filter(([_, values]) => values.length > 0)
      .map(([key, values]) => {
        let name = t('form-builder:error-states.unknown-item');
        if (key === form.id) {
          name = LanguageUtils.getTranslation('title', form.translations) || t('form-builder:error-states.form-title');
        } else {
          for (const [sectionIndex, section] of Object.entries(form.sections)) {
            if (section.id === key) {
              name =
                LanguageUtils.getTranslation('title', section.translations) ||
                t('form-builder:error-states.section-title', { index: parseInt(sectionIndex) + 1 });
              break;
            }
            for (const [actionIndex, action] of Object.entries(section.actions)) {
              if (action.id === key) {
                name = `${parseInt(sectionIndex) + 1}.${parseInt(actionIndex) + 1} ${t(`form-builder:action-types.${action.type}`)}`;

                const getTitle = ActionTypes[action.type].actionTitle;
                const title = getTitle ? getTitle(action, i18n.language) : null;
                if (title) {
                  name += ` - ${title}`;
                }
              }
            }
          }
        }

        return values.map((message) => (
          <li key={`${name}-${message}`} className="list-disc">
            <b>{name}:</b> {message}
          </li>
        ));
      })
      .flat();
  }, [invalidStates, t, form.id, form.translations, form.sections, i18n.language]);

  const saveForm = useCallback(
    (checkForInvalidState = true) => {
      const formWithoutAnswers = {
        ...form,
        sections: form.sections.map((section) => ({
          ...section,
          actions: section.actions.map((action) => ({
            ...action,
            response: null,
          })),
        })),
      };

      if (checkForInvalidState && invalidStateMessages.length > 0) {
        setShowInvalidStateModal(true);
        return Promise.resolve(false);
      }

      // Existing form
      if (templateFormId) {
        return templateService
          .updateTemplate(templateFormId, formWithoutAnswers)
          .then((res) => {
            toastsRef.current.addToast({ title: t('form-builder:toasters.form-updated.title'), type: ToastType.SUCCESS, expiresInMs: 5000 });
            setChangesPending(false);
            localStorage.removeItem(`form-builder.failsafe.${failsafeKey}.${templateFormId}`);
            return new Promise<boolean>((resolve) => {
              setTimeout(() => {
                navigate(`${leadingUrl}/${res.data.id}/${res.data.version}`, { replace: true });
                resolve(true);
              }, 100);
            });
          })
          .catch((e) => {
            toastsRef.current.addToast({
              title: t('form-builder:toasters.form-updated-fail.title'),
              type: ToastType.ERROR,
              description: e?.meta?.message,
              expiresInMs: 10000,
            });
            return false;
          });
      }
      // New Form
      else {
        return templateService
          .createTemplate(formWithoutAnswers)
          .then((res) => {
            toastsRef.current.addToast({ title: t('form-builder:toasters.form-created.title'), type: ToastType.SUCCESS, expiresInMs: 5000 });
            setChangesPending(false);
            localStorage.removeItem(`form-builder.failsafe.${failsafeKey}.${templateFormId}`);
            return new Promise<boolean>((resolve) => {
              setTimeout(() => {
                navigate(`${leadingUrl}/${res.data.id}/${res.data.version}`, { replace: true });
                resolve(true);
              }, 100);
            });
          })
          .catch((e) => {
            toastsRef.current.addToast({
              title: t('form-builder:toasters.form-created-fail.title'),
              type: ToastType.ERROR,
              description: e?.meta?.message,
              expiresInMs: 10000,
            });
            return false;
          });
      }
    },
    [failsafeKey, form, invalidStateMessages.length, leadingUrl, navigate, t, templateFormId, templateService],
  );

  useEffect(() => {
    const eventHandler = (event: KeyboardEvent) => {
      if (event.key === 'j' && event.ctrlKey) {
        event.preventDefault();
        setShowJson((current) => !current);
      } else if (event.key === 's' && event.ctrlKey) {
        event.preventDefault();
        saveForm();
      }
    };

    document.addEventListener('keydown', eventHandler);

    return () => {
      document.removeEventListener('keydown', eventHandler);
    };
  }, [saveForm]);

  useEffect(() => {
    if (form.sections.length === 0) {
      setForm((prev) => ({
        ...prev,
        sections: [
          {
            id: uuid(),
            title: '',
            actions: [],
            translations: {},
            visible: 'true',
            triggerSteps: [],
          },
        ],
      }));
    }
  }, [form.sections.length]);

  const changeIds = useCallback((sections: Section[], resetDataIds?: boolean): [sections: Section[], mappedIds: Record<string, string>] => {
    const mappedIds: Record<string, string> = {};
    const generateId = (previousId: string) => {
      const newId = uuid();
      mappedIds[previousId] = newId;
      return newId;
    };

    const unmapDataIds = (actionData: any) => {
      if (!actionData) return actionData;

      if (actionData.sourceType) {
        actionData.sourceType = '';
      }

      if (actionData.templateFormId) {
        actionData.templateFormId = '';
      }

      if (actionData.lockedTemplateId) {
        actionData.lockedTemplateId = '';
      }

      if (actionData.templateFormId) {
        actionData.templateFormId = '';
      }

      return actionData;
    };

    let newSections = sections.map((section) => ({
      ...section,
      id: generateId(section.id),
      actions: section.actions.map((action) => ({
        ...action,
        id: generateId(action.id),
        response: null,
      })),
    }));

    for (const oldId in mappedIds) {
      newSections = newSections.map((section) => ({
        ...section,
        visible: section.visible?.replaceAll(oldId, mappedIds[oldId]),
        triggerSteps: [
          ...section.triggerSteps.map((triggerStep) => ({
            ...triggerStep,
            stepId: triggerStep.stepId.replaceAll(oldId, mappedIds[oldId]),
            condition: triggerStep.condition.replaceAll(oldId, mappedIds[oldId]),
          })),
        ],
        actions: section.actions.map((action) => ({
          ...action,
          visible: action.visible?.replaceAll(oldId, mappedIds[oldId]),
          required: action.required?.replaceAll(oldId, mappedIds[oldId]),
          riskConditions: action.riskConditions?.map((condition) => condition.replaceAll(oldId, mappedIds[oldId])),
          data: resetDataIds ? unmapDataIds(action.data) : action.data,
        })),
      }));
    }

    return [newSections, mappedIds];
  }, []);

  const createCopy = useCallback(
    (sourceForm: FormBuilderForm) => {
      // Skip the route guard when navigating to the new copied form
      setCreatingCopy(true);

      return Promise.resolve()
        .then(() => {
          setSelectedSectionId('');
          setSelectedEditorId('');
          setSelectedEditorType(EditorType.FORM);

          const copiedSections = changeIds(sourceForm.sections);
          const newSections = copiedSections[0];
          const mappedIds = copiedSections[1];

          const updatedTranslations = {
            ...sourceForm.translations,
            [i18n.language]: {
              ...sourceForm.translations?.[i18n.language],
              title: t('form-builder:copy-title', {
                title: LanguageUtils.getTranslation('title', sourceForm.translations),
              }),
            },
          };

          const newForm = {
            ...sourceForm,
            id: uuid(),
            version: 1,
            translations: updatedTranslations,
            sections: newSections,
            placeholders: sourceForm.placeholders?.map((place) => ({
              ...place,
              actionId: mappedIds[place.targetId] || place.targetId,
              referencedActionId: mappedIds[place.referencedActionId] || place.referencedActionId,
              actionIdContainingAssociation:
                place.actionIdContainingAssociation && (mappedIds[place.actionIdContainingAssociation] || place.actionIdContainingAssociation),
            })),
          };
          setChangesPending(true);
          setForm(newForm);
          setLoadedType(undefined);
          navigate(leadingUrl, { replace: true });
        })
        .then(() => {
          setCreatingCopy(false);
        });
    },
    [changeIds, i18n.language, leadingUrl, navigate, t],
  );

  useEffect(() => {
    if (!templateFormId) {
      return;
    }

    const failsafe = localStorage.getItem(`form-builder.failsafe.${failsafeKey}.${templateFormId}`);
    const makeCopy = searchParams.get('copy');
    const copyValue = localStorage.getItem(`form-builder.copy.${makeCopy}`);

    if (makeCopy) {
      if (copyValue) {
        createCopy(JSON.parse(copyValue));
        localStorage.removeItem(`form-builder.copy.${makeCopy}`);
      }
      return;
    }

    if (!failsafe) {
      setLoading(true);
      templateService
        .getFormTemplate(templateFormId, templateFormVersion ? parseInt(templateFormVersion, 10) : undefined)
        .then((res) => {
          const formTemplate = { ...res.data, placeholders: res.data.placeholders as FormBuilderPlaceholder[] };
          setForm(formTemplate);
          setOriginalForm(formTemplate);
          setLoadedType(res.data.type);
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    const form = JSON.parse(failsafe);
    const shouldMigratePlaceholders = form.placeholders.some((placeholder: any) => !('targetId' in placeholder));
    if (shouldMigratePlaceholders) {
      setForm({
        ...form,
        placeholders: form.placeholders.map((placeholder: any) => ({
          ...placeholder,
          targetId: placeholder.actionId,
          target: 0,
        })),
      });
    } else {
      setForm(form);
    }
    setLoading(true);
    setLoadedType(form.type);
    setChangesPending(true);
    // load the original one from server
    templateService
      .getFormTemplate(templateFormId, templateFormVersion ? parseInt(templateFormVersion, 10) : undefined)
      .then((res) => {
        setOriginalForm({ ...res.data, placeholders: res.data.placeholders as FormBuilderPlaceholder[] });
      })
      .finally(() => {
        setLoading(false);
      });

    toastsRef.current.addToast({
      title: t('form-builder:toasters.form-restored.title'),
      description: t('form-builder:toasters.form-restored.description'),
      type: ToastType.INFO,
      expiresInMs: 7000,
    });
  }, [createCopy, failsafeKey, searchParams, t, templateFormId, templateFormVersion, templateService]);

  const cloneAction = (section: Section, action: Action) => {
    let clonedAction = ObjectUtils.DeepClone(action);
    clonedAction.id = uuid();

    // clone placeholders
    const newPlaceholderIds: Record<string, string> = {};
    const toClonePlaceholders = form.placeholders?.filter((x) => x.targetId === action.id) ?? [];
    const clonedPlaceholders = toClonePlaceholders.map((x) => ({
      ...x,
      actionId: clonedAction.id,
      placeholder: (newPlaceholderIds[x.placeholder] = '${{' + uuid() + '}}'),
      placeholderId: undefined,
    }));
    const newPlaceholders = [...(form.placeholders ?? []), ...clonedPlaceholders];
    let actionString = JSON.stringify(clonedAction);
    for (const [oldId, newId] of Object.entries(newPlaceholderIds)) {
      actionString = actionString.replaceAll(oldId, newId);
    }
    clonedAction = JSON.parse(actionString);

    const updatedSections = ObjectUtils.DeepClone(form.sections).map((sec) => {
      if (sec.id === section.id) {
        sec.actions.splice(sec.actions.findIndex((x) => x.id === action.id) + 1, 0, clonedAction);
      }
      return sec;
    });
    setForm((prev) => ({ ...prev, sections: updatedSections, placeholders: newPlaceholders }));
    nextTick(() => {
      setSelectedEditor(clonedAction.id, EditorType.ACTION);
    });
    setChangesPending(true);
    localStorage.setItem(`form-builder.failsafe.${failsafeKey}.${templateFormId}`, JSON.stringify({ ...form, sections: updatedSections }));
  };

  const cloneSection = (section: Section) => {
    const clonedSection = ObjectUtils.DeepClone(section);
    clonedSection.id = uuid();
    clonedSection.translations ??= {};
    clonedSection.translations[i18n.language] ??= {};
    clonedSection.translations[i18n.language]!.title = t('form-builder:copy-title', {
      title: LanguageUtils.getTranslation('title', clonedSection.translations),
    });

    const newPlaceholders: FormBuilderPlaceholder[][] = [];
    clonedSection.actions = clonedSection.actions.map((action) => {
      let clonedAction = { ...action, id: uuid() };
      const newPlaceholderIds: Record<string, string> = {};
      const toClonePlaceholders = form.placeholders?.filter((x) => x.targetId === action.id) ?? [];
      const clonedPlaceholders = toClonePlaceholders.map((x) => ({
        ...x,
        actionId: clonedAction.id,
        placeholder: (newPlaceholderIds[x.placeholder] = '${{' + uuid() + '}}'),
        placeholderId: undefined,
      }));
      newPlaceholders.push(clonedPlaceholders);
      let actionString = JSON.stringify(clonedAction);
      for (const [oldId, newId] of Object.entries(newPlaceholderIds)) {
        actionString = actionString.replaceAll(oldId, newId);
      }
      clonedAction = JSON.parse(actionString);

      return clonedAction;
    });

    const newSections = ObjectUtils.DeepClone(form.sections);
    newSections.splice(newSections.findIndex((x) => x.id === section.id) + 1, 0, clonedSection);
    setForm((prev) => ({ ...prev, sections: newSections, placeholders: [...(form.placeholders ?? []), ...newPlaceholders.flat()] }));
    nextTick(() => {
      setSelectedEditor(clonedSection.id, EditorType.SECTION);
    });
    setChangesPending(true);
    localStorage.setItem(`form-builder.failsafe.${failsafeKey}.${templateFormId}`, JSON.stringify({ ...form, sections: newSections }));
  };

  const setSelectedEditor = useCallback(
    (id: string | null, type: EditorType) => {
      const newValue = id === selectedEditorId ? '' : id || '';
      setSelectedEditorType(newValue ? type : null);
      setSelectedEditorId(newValue);

      if (type === EditorType.ACTION || type === EditorType.SECTION) {
        setSelectedSectionId(
          id === selectedEditorId ? '' : form.sections.find((section) => section.id === id || section.actions.find((x) => x.id === id))?.id || '',
        );
      } else {
        setSelectedSectionId('');
      }
    },
    [form.sections, selectedEditorId],
  );

  const addSection = () => {
    const newSections = [...form.sections, { id: uuid(), title: '', actions: [], translations: {}, triggerSteps: [] }];

    setForm((prev) => ({ ...prev, sections: newSections }));
    setChangesPending(true);
    localStorage.setItem(`form-builder.failsafe.${failsafeKey}.${templateFormId}`, JSON.stringify({ ...form, sections: newSections }));
  };

  const patchSection = (sectionId: string, value: Partial<Section>) => {
    const newSections = form.sections.map((section) => (section.id !== sectionId ? section : { ...section, ...value }));

    setForm((prev) => ({ ...prev, sections: newSections }));
    setChangesPending(true);
    localStorage.setItem(`form-builder.failsafe.${failsafeKey}.${templateFormId}`, JSON.stringify({ ...form, sections: newSections }));
  };

  const removePlaceholders = (actionIds: string[]) => {
    if (!form.placeholders) return [];

    return form.placeholders.filter((placeholder) => {
      const actionIdToCheck = placeholder.actionIdContainingAssociation ?? placeholder.referencedActionId;
      return !actionIds.includes(actionIdToCheck) && !actionIds.includes(placeholder.targetId);
    });
  };

  const removeSection = (sectionId: string) => {
    if (sectionId === selectedSectionId) {
      setSelectedEditorType(null);
      setSelectedEditorId('');
    }

    const removedActionIds = form.sections.find((section) => section.id === sectionId)?.actions.map((action) => action.id) ?? [];

    const newSections = form.sections.filter((x) => x.id !== sectionId);

    setForm((prev) => ({ ...prev, sections: newSections, placeholders: removePlaceholders(removedActionIds) }));
    setChangesPending(true);
    localStorage.setItem(`form-builder.failsafe.${failsafeKey}.${templateFormId}`, JSON.stringify({ ...form, sections: newSections }));
  };

  const patchSelectedActionData = useCallback(
    (actionData: any) => {
      setForm((prev) => {
        const newSections = prev.sections.map((section) => {
          if (section.id !== selectedSectionId) {
            return section;
          }

          const sectionCopy = ObjectUtils.DeepClone(section);
          const actionIndex = sectionCopy.actions.findIndex((x) => x.id === selectedEditorId);
          const action = sectionCopy.actions[actionIndex];
          if (action) {
            sectionCopy.actions[actionIndex] = Object.assign({}, action, actionData);
          }
          return sectionCopy;
        });

        localStorage.setItem(`form-builder.failsafe.${failsafeKey}.${templateFormId}`, JSON.stringify({ ...prev, sections: newSections }));

        return { ...prev, sections: newSections };
      });
      setChangesPending(true);
    },
    [failsafeKey, selectedEditorId, selectedSectionId, templateFormId],
  );

  const removeSelectedAction = () => {
    const newSections = form.sections.map((section) => {
      section.actions = section.actions.filter((x) => x.id !== selectedEditorId);
      return section;
    });

    setForm((prev) => ({ ...prev, sections: newSections, placeholders: removePlaceholders([selectedEditorId]) }));
    setSelectedEditorType(null);
    setSelectedEditorId('');
    setChangesPending(true);
    localStorage.setItem(`form-builder.failsafe.${failsafeKey}.${templateFormId}`, JSON.stringify({ ...form, sections: newSections }));
  };

  const patchFormConfig = (partialFormConfig: Partial<FormBuilderForm>) => {
    setForm((prev) => {
      const newFormConfig = Object.assign(ObjectUtils.DeepClone(prev), partialFormConfig);
      localStorage.setItem(`form-builder.failsafe.${failsafeKey}.${templateFormId}`, JSON.stringify(newFormConfig));
      return newFormConfig;
    });
    setChangesPending(true);
  };

  const selectedAction = useMemo(
    () => form.sections.map((section) => section.actions.find((action) => action.id === selectedEditorId)).find((x) => !!x),
    [form.sections, selectedEditorId],
  );

  // Validate form whenever anything changes
  useEffect(() => validateForm(), [form, validateForm]);

  const deleteFailsafe = async () => {
    localStorage.removeItem(`form-builder.failsafe.${failsafeKey}.${templateFormId}`);
    setChangesPending(false);
  };

  const exportForm = useCallback(() => {
    const passphrase = SHA256(Math.floor(Math.random() * 999999).toString()).toString();
    const data = JSON.stringify(form);
    const encryptedData = passphrase + '\0' + AES.encrypt(data, passphrase);

    const blob = new Blob([encryptedData], { type: 'text/plain' });
    const el = document.createElement('a');
    el.href = URL.createObjectURL(blob);
    el.download =
      LanguageUtils.getTranslation('title', form.translations) + ' - Export ' + DateUtils.formatDateTime(new Date(), true).replaceAll('/', '-');
    el.click();

    setTimeout(() => {
      URL.revokeObjectURL(el.href);
    }, 100);
  }, [form]);

  const importForm = useCallback(() => {
    const inputEl = document.createElement('input');
    inputEl.type = 'file';

    inputEl.onchange = () => {
      const file = inputEl.files ? inputEl.files[0] : null;
      if (!file) {
        return;
      }

      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = function (evt) {
        const fileContent = evt?.target?.result?.toString();
        if (!fileContent) {
          toastsRef.current.addToast({
            title: t('form-builder:toasters.import-fail-no-file.title'),
            type: ToastType.ERROR,
            description: t('form-builder:toasters.import-fail-no-file.description'),
            expiresInMs: 10000,
          });
          return;
        }

        const [passphrase, encryptedData] = fileContent.split('\0');
        if (!passphrase || !encryptedData) {
          toastsRef.current.addToast({
            title: t('form-builder:toasters.import-fail-decode-error.title'),
            type: ToastType.ERROR,
            description: t('form-builder:toasters.import-fail-decode-error.description'),
            expiresInMs: 10000,
          });
          return;
        }

        let data = '';
        try {
          data = AES.decrypt(encryptedData, passphrase).toString(enc.Utf8);
          if (!data) {
            toastsRef.current.addToast({
              title: t('form-builder:toasters.import-fail-decrypt-error.title'),
              type: ToastType.ERROR,
              description: t('form-builder:toasters.import-fail-decrypt-error.description'),
              expiresInMs: 10000,
            });
            return;
          }
        } catch {
          toastsRef.current.addToast({
            title: t('form-builder:toasters.import-fail-read-error.title'),
            type: ToastType.ERROR,
            description: t('form-builder:toasters.import-fail-read-error.description'),
            expiresInMs: 10000,
          });
          return;
        }

        let json: FormBuilderForm;
        try {
          json = JSON.parse(data);
        } catch {
          toastsRef.current.addToast({
            title: t('form-builder:toasters.import-fail-json-error.title'),
            type: ToastType.ERROR,
            description: t('form-builder:toasters.import-fail-json-error.description'),
            expiresInMs: 10000,
          });
          return;
        }

        localStorage.removeItem(`form-builder.failsafe.${failsafeKey}.${templateFormId}`);
        setForm({ ...json, type: form.type === FormType.Resource ? form.type : json.type, sections: changeIds(json.sections, true)[0], id: uuid() });
        setChangesPending(true);
        setTimeout(() => {
          navigate(leadingUrl, { replace: true });
        }, 100);

        toastsRef.current.addToast({
          title: t('form-builder:toasters.import-success.title', { title: LanguageUtils.getTranslation('title', json.translations) || 'form' }),
          type: ToastType.SUCCESS,
          expiresInMs: 5000,
        });
      };

      reader.onerror = function () {
        toastsRef.current.addToast({
          title: t('form-builder:toasters.import-fail-read-error.title'),
          type: ToastType.ERROR,
          description: t('form-builder:toasters.import-fail-read-error.description'),
          expiresInMs: 10000,
        });
      };
    };

    inputEl.click();
  }, [changeIds, failsafeKey, form.type, leadingUrl, navigate, t, templateFormId]);

  useEffect(() => {
    formRendererRef.current?.highlight(selectedAction?.id ? { sourceId: selectedAction.id, sectionId: selectedSectionId, formId: undefined } : null);
  }, [form.id, selectedAction?.id, selectedSectionId]);

  const formTitle = useMemo(() => {
    return LanguageUtils.getTranslation('title', form.translations, i18n.language) || '';
  }, [form.translations, i18n.language]);

  const [clonedItems, setClonedItems] = useState<Section[] | null>(null);
  const disableDragOverAdding = useRef(false);
  const sectionIds = useMemo(() => form.sections.map((x) => x.id), [form.sections]);

  const onDragCancel = () => {
    disableDragOverAdding.current = false;

    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setForm((prev) => ({ ...prev, sections: clonedItems }));
    }

    setClonedItems(null);
    newActionTypeIds();
  };

  const findContainer = useCallback(
    (id: UniqueIdentifier) => {
      return form.sections.find((x) => x.id === id || x.actions.some((a) => a.id === id))?.id;
    },
    [form.sections],
  );

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      disableDragOverAdding.current = false;

      const { active, over } = event;
      const activeId = active.id;

      newActionTypeIds();

      // moving sections
      if (sectionIds.includes(activeId as string) && over?.id) {
        setForm((form) => {
          const activeIndex = form.sections.findIndex((x) => x.id === activeId);
          const overIndex = form.sections.findIndex((x) => x.id === over.id);

          const newForm = { ...form, sections: arrayMove(form.sections, activeIndex, overIndex) };
          localStorage.setItem(`form-builder.failsafe.${failsafeKey}.${templateFormId}`, JSON.stringify(newForm));
          return newForm;
        });
        setChangesPending(true);
        return;
      }

      const activeContainer = findContainer(activeId);

      if (!activeContainer) {
        return;
      }

      const overId = over?.id;

      if (overId == null) {
        return;
      }

      const overContainer = findContainer(overId);

      //moving actions
      if (overContainer) {
        const activeContainerIndex = form.sections.findIndex((x) => x.id === activeContainer);
        const overContainerIndex = form.sections.findIndex((x) => x.id === overContainer);
        const activeIndex = form.sections[activeContainerIndex].actions.findIndex((x) => x.id === activeId);
        const overIndex = form.sections[overContainerIndex].actions.findIndex((x) => x.id === overId);

        setForm((form) => {
          const newForm = {
            ...form,
            sections: form.sections.map((section) =>
              section.id === overContainer ? { ...section, actions: arrayMove(section.actions, activeIndex, overIndex) } : section,
            ),
          };

          localStorage.setItem(`form-builder.failsafe.${failsafeKey}.${templateFormId}`, JSON.stringify(newForm));
          return newForm;
        });

        setTimeout(() => {
          setSelectedEditorType(EditorType.ACTION);
          setSelectedEditorId(form.sections[activeContainerIndex].actions[activeIndex]?.id || '');
          setSelectedSectionId(overContainer || '');
        }, 50);

        setChangesPending(true);
      }
    },
    [failsafeKey, findContainer, form.sections, newActionTypeIds, sectionIds, templateFormId],
  );

  const onDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event;
      const overId = over?.id;

      if (overId == null || sectionIds.includes(active.id as string)) {
        return;
      }

      const overContainer = findContainer(overId);
      const newActionType = actionTypes.find((x) => x.type === active.data.current?.item.type) as Action;
      if (!disableDragOverAdding.current && active.data.current?.type === BuilderDroppableTypes.Sidebar) {
        disableDragOverAdding.current = true;

        setForm((form) => {
          const overContainerIndex = form.sections.findIndex((x) => x.id === overContainer);
          const overItems = form.sections.find((x) => x.id === overContainer)?.actions.map((x) => x.id);
          const overIndex = overItems?.indexOf(overId as string) ?? 0;

          if (overContainerIndex === -1) {
            return form;
          }

          let newIndex: number;

          if (form.sections.some((x) => x.id === overId)) {
            newIndex = (overItems?.length ?? -1) + 1;
          } else {
            const isBelowOverItem = over && active.rect.current.translated && active.rect.current.translated.top > over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;

            newIndex = overIndex >= 0 ? overIndex + modifier : (overItems?.length ?? -1) + 1;
          }

          const newSections = ObjectUtils.DeepClone(form.sections);
          newSections[overContainerIndex].actions = [
            ...form.sections[overContainerIndex].actions.slice(0, newIndex),
            ObjectUtils.DeepClone(newActionType),
            ...form.sections[overContainerIndex].actions.slice(newIndex),
          ];

          return {
            ...form,
            sections: newSections,
          };
        });

        return;
      }

      const activeContainer = findContainer(active.id);

      if (!overContainer || !activeContainer) {
        return;
      }

      if (activeContainer !== overContainer) {
        setForm((form) => {
          const activeContainerIndex = form.sections.findIndex((x) => x.id === activeContainer);
          const overContainerIndex = form.sections.findIndex((x) => x.id === overContainer);
          const activeItems = form.sections.find((x) => x.id === activeContainer)?.actions.map((x) => x.id);
          const overItems = form.sections.find((x) => x.id === overContainer)?.actions.map((x) => x.id);
          const activeIndex = activeItems?.indexOf(active.id as string) ?? 0;
          const overIndex = overItems?.indexOf(overId as string) ?? 0;

          let newIndex: number;

          if (overId in form.sections) {
            newIndex = (overItems?.length ?? -1) + 1;
          } else {
            const isBelowOverItem = over && active.rect.current.translated && active.rect.current.translated.top > over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;

            newIndex = overIndex >= 0 ? overIndex + modifier : (overItems?.length ?? -1) + 1;
          }

          const newSections = ObjectUtils.DeepClone(form.sections);
          newSections[activeContainerIndex].actions = form.sections[activeContainerIndex].actions.filter((item) => item.id !== active.id);
          newSections[overContainerIndex].actions = [
            ...form.sections[overContainerIndex].actions.slice(0, newIndex),
            form.sections[activeContainerIndex].actions[activeIndex],
            ...form.sections[overContainerIndex].actions.slice(newIndex, form.sections[overContainerIndex].actions.length),
          ];

          return {
            ...form,
            sections: newSections,
          };
        });
      }
    },
    [actionTypes, findContainer, sectionIds],
  );

  const makeCopy = () => {
    const copyId = uuid();
    localStorage.setItem(`form-builder.copy.${copyId}`, JSON.stringify(form));
    window.open(location.pathname + `?copy=${copyId}`, '_blank');
  };

  useEffect(() => {
    setPreviewLanguageCode(i18n.language);
  }, [i18n.language]);

  return (
    <DndContext
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={() => {
        setClonedItems(form.sections);
      }}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
      onDragOver={onDragOver}
    >
      <DragOverlay dropAnimation={null}>
        <FormBuilderDragOverlay />
      </DragOverlay>
      <TopNavPortal>
        <StaticBreadCrumb currentStepName={formTitle} breadCrumbs={breadCrumbs} />
      </TopNavPortal>
      <PageLoader loading={loading}>
        <div className="flex min-w-full max-w-0 items-center justify-between">
          <div className="flex items-center justify-between truncate p-6">
            <div className="flex cursor-pointer items-center truncate" onClick={() => setSelectedEditor(form.id, EditorType.FORM)}>
              <Heading
                data-cy="form-title"
                size={HeadingSize.H3}
                className="truncate"
                textColor={selectedEditorType === EditorType.FORM ? 'text-primary-2' : 'text-black'}
              >
                {formTitle}
              </Heading>
              <div className="flex items-center pl-3">
                <span data-cy="version" className="text-dpm-14">
                  v{form.version}
                </span>
              </div>
              {form.archivedUtc && (
                <span className="text-semantic-3 pl-3" data-cy="archived-warning">
                  {t('form-builder:archived-label')}
                </span>
              )}
            </div>
          </div>
          <div className="flex items-end justify-end">
            <div className="mr-8 flex items-center gap-4 py-4">
              <div>
                <Button onClick={addSection} data-cy="add-section" type={ButtonType.PRIMARY}>
                  {t('form-builder:add-step')}
                </Button>
              </div>
              <div>
                <Button type={ButtonType.PRIMARY} onClick={() => saveForm()} disabled={!changesPending} data-cy="save-template">
                  {!templateFormId ? t('form-builder:create-template') : t('form-builder:update-template')}
                </Button>
              </div>
              <div>
                <ContextMenu
                  items={[
                    {
                      title: t('form-builder:header.actions.create-copy'),
                      icon: <CopyIcon className="h-5 w-5" />,
                      onClick: () => makeCopy(),
                      hide: !templateFormId,
                    },
                    {
                      title: t('form-builder:header.actions.edit'),
                      icon: <EditIcon className="h-5 w-5" />,
                      onClick: () => setSelectedEditor(form.id, EditorType.FORM),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="border-gray-5 mt-2 flex border-t pt-2">
          <div
            className="z-50 w-64 flex-shrink-0 overflow-y-scroll"
            style={{
              height: `calc(100vh - ${tailwind.theme.spacing['20']} - ${tailwind.theme.spacing['28']} - ${tailwind.theme.spacing['8']})`,
            }}
          >
            <ActionTypesList actionTypes={filteredActionTypes} />
          </div>
          <div className="flex" style={{ width: `calc(100vw - ${tailwind.theme.spacing['24']} - ${tailwind.theme.spacing['64']})` }}>
            <div
              className="w-1/2 overflow-y-scroll px-4"
              style={{
                height: `calc(100vh - ${tailwind.theme.spacing['20']} - ${tailwind.theme.spacing['28']} - ${tailwind.theme.spacing['8']})`,
              }}
            >
              <FormBuilderEditor
                actionTypes={filteredActionTypes}
                formConfig={form}
                sections={form.sections}
                removeSection={removeSection}
                selectedEditorId={selectedEditorId}
                setSelectedEditor={setSelectedEditor}
                invalidStates={invalidStates}
                cloneAction={cloneAction}
                cloneSection={cloneSection}
                removeAction={removeSelectedAction}
              />
            </div>
            <div
              className="w-1/2 overflow-y-auto px-2"
              style={{
                height: `calc(100vh - ${tailwind.theme.spacing['20']} - ${tailwind.theme.spacing['28']} - ${tailwind.theme.spacing['8']})`,
              }}
            >
              <TabStrip headerClassName="px-6" contentClassName="px-6 h-full" evenTabs enableSticky>
                <TabStrip.TabHeader id="properties" text={t('form-builder:tabs.properties')} value={null} data-cy="form-properties-tab" />
                {!hidePreview && (
                  <TabStrip.TabHeader id="preview" text={t('form-builder:tabs.preview')} value={null} data-cy="form-preview-tab">
                    <TabStrip.TabHeader.Slot name="Trailing">
                      <div className="right-0 top-0 float-right flex items-center justify-center gap-1">
                        <PreviewTabStripLanguageSwitcher selected={previewLanguageCode} onChange={setPreviewLanguageCode} />
                      </div>
                    </TabStrip.TabHeader.Slot>
                  </TabStrip.TabHeader>
                )}
                {showJson && <TabStrip.TabHeader id="json" text={t('form-builder:tabs.json')} value={null} data-cy="form-json-tab" />}

                <TabStrip.TabContent forId="properties" data-cy="form-properties">
                  {(active: boolean) =>
                    active && (
                      <div className="relative my-3 min-h-24">
                        <Suspense fallback={<PageLoader loading loaderSize={16} />}>
                          {!selectedEditorType && <div className="italic">{t('form-builder:nothing-selected')}</div>}
                          {!!selectedEditorType && selectedEditorType === EditorType.ACTION && (
                            <PropertiesEditors.ActionPropertiesEditor
                              key={selectedAction?.id}
                              action={selectedAction as Action}
                              patchAction={patchSelectedActionData}
                              form={form}
                              referencedForms={referencedForms}
                              patchForm={patchFormConfig}
                              invalidStates={invalidStates}
                              builderBaseUrl={leadingUrl}
                            />
                          )}
                          {!!selectedEditorType && selectedEditorType === EditorType.SECTION && selectedSection && (
                            <PropertiesEditors.SectionPropertiesEditor
                              key={selectedSection?.id}
                              form={form}
                              patchSection={(value) => patchSection(selectedSectionId, value)}
                              section={selectedSection}
                            />
                          )}
                          {!!selectedEditorType && selectedEditorType === EditorType.FORM && (
                            <PropertiesEditors.FormPropertiesEditor
                              form={form}
                              patchFormConfig={patchFormConfig}
                              loadedType={loadedType}
                              originalForm={originalForm}
                              referencedForms={referencedForms}
                            />
                          )}
                        </Suspense>
                      </div>
                    )
                  }
                </TabStrip.TabContent>
                {!hidePreview && (
                  <TabStrip.TabContent forId="preview" data-cy="form-preview">
                    {(active: boolean) =>
                      active && (
                        <FormRendererV2
                          ref={formRendererRef}
                          form={form as any}
                          languageOverride={previewLanguageCode}
                          initialMode={FormRendererMode.EditView}
                          canEdit
                          renderAllSteps
                        />
                      )
                    }
                  </TabStrip.TabContent>
                )}

                {showJson && (
                  <TabStrip.TabContent forId="json" data-cy="form-json">
                    <div className="my-4 flex justify-end gap-4">
                      <Button onClick={importForm}>{t('form-builder:import-form')}</Button>
                      <Button onClick={exportForm}>{t('form-builder:export-form')}</Button>
                    </div>
                    <pre className="select-text">{JSON.stringify(form, null, 4)}</pre>
                  </TabStrip.TabContent>
                )}
              </TabStrip>
            </div>
          </div>
        </div>

        <RouteGuard when={() => changesPending && !creatingCopy} onSave={saveForm} onContinue={deleteFailsafe} />

        <ModalContext.Provider value={{ open: showInvalidStateModal, onClose: () => setShowInvalidStateModal(false), modalWidth: 'w-2/6' }}>
          <StandardModal
            title={t('form-builder:modals.invalid-state.title')}
            subTitle={t('form-builder:modals.invalid-state.description')}
            cancelButtonTitle={t('form-builder:modals.invalid-state.cancel')}
            confirmButtonTitle={t('form-builder:modals.invalid-state.save')}
            confirmDisabled={!allowSavingInInvalidState}
            onCancelClick={() => setShowInvalidStateModal(false)}
            onConfirmClick={() => {
              saveForm(false);
              setShowInvalidStateModal(false);
            }}
          >
            <ul>{invalidStateMessages}</ul>
          </StandardModal>
        </ModalContext.Provider>
      </PageLoader>
    </DndContext>
  );
};

export default FormBuilderBase;

const PreviewTabStripLanguageSwitcher: FC<ComponentProps<typeof TranslatableInputButtons>> = (props) => {
  const { selected } = useTabHeader();

  return <>{selected && <TranslatableInputButtons {...props} />}</>;
};
